import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/ui';
export default createGatsbyPageComponent('Page');

export const QUERY = graphql`
query PageQuery($id: ID!) {
  platform { entityTypeDefinition(entityTypeName: "Page") {
    typeSlug
  }
  page(id: $id) {
    
      id slug path 
      name 
      title 
      pretitle
      subtitle
      titleRich { text }
      subtitleRich { text }
      published
      content { text }
      options { text }
      intro { plain text }
      outro { text }
      orderNumber
      openGraph { title description { plain } image { thumbnails { card { url url2x } bubble { url url2x } } } }
      banners {
        id name slug title description { text } alternateText
        image { 
          id url url2x width height thumbnails { halfBanner { url url2x } mainBanner { url url2x } } 
        }
      }
      layout {
        id name slug component 
      }
      videos {
        id name url
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      imageAssets {
        id image { id url url2x type }
      }
      people {
        id name slug path
        organisation { id name }
        linkedin
        position
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      embeds {
        citations {
          slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
        }
        pages {
          id name slug path
          openGraph {
            title description { plain }
            image { thumbnails { bubble { url url2x } card { url url2x } } }
          }
        }
        people {
          id name slug path
          organisation { id name }
          position
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        }
        imageAssets {
          id
          slug
          name 
          image {
            id url url2x type
          }
        }
        posts {
          id name slug path type { name }
          openGraph {
            title description { plain text }
            image { url url2x type }
          }
        }
      }
      parent {
        __typename id name path slug title subtitle pretitle 
        content { text }
        children {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { text } image { thumbnails { card { url url2x } } } }
          intro { text }
          orderNumber
        }
      }
      bannerLinks {
        id name slug url __typename
        type { id name }
        typeLabel
        banner {
          id name slug
          image { id url url2x width height thumbnails { halfBanner { url url2x } mainBanner { url url2x } } }
        }
        page { 
          id name slug path __typename 
          type { id name }
          title
          subtitle
          openGraph { id image { id url url2x } }
        }
        post { 
          id name slug path __typename 
          typeLabel
          title
          subtitle
          openGraph { id image { id url url2x } }
        }
        economy { 
          id name slug path __typename 
          typeLabel
          openGraph { id image { id url url2x } }
        }
        organisation {
          id name slug path __typename 
          typeLabel
          openGraph { id image { id url url2x } }
        }
        person {
          id name slug path __typename 
          typeLabel
          openGraph { id image { id url url2x } }
        }
        product {
          id name slug path __typename 
          typeLabel
          openGraph { id image { id url url2x } }
        }
        team {
          id name slug path __typename 
          typeLabel
          openGraph { id image { id url url2x } }
        }
        bannerTitle
        bannerSubtitle
        openGraph { id image { id url thumbnails { card { url url2x } } } }
      }
      cardLinks {
        id name path slug url __typename
        type { id name }
        typeLabel
        bannerTitle
        bannerSubtitle
        openGraph { id image { id url url2x thumbnails { card { url url2x } } } }
        page { 
          id name slug path __typename 
          typeLabel
          type { id name }
          title
          subtitle
          openGraph { id image { id url url2x } }
        }
        event { 
          id name slug path __typename 
          typeLabel
          title
          subtitle
          openGraph { id image { id url url2x } }
        }
        post { 
          id name slug path __typename 
          typeLabel
          title
          subtitle
          openGraph { id image { id url url2x } }
        }
        economy { 
          id name slug path __typename 
          typeLabel
          openGraph { id image { id url url2x } }
        }
        organisation {
          id name slug path __typename 
          typeLabel
          openGraph { id image { id url url2x } }
        }
        person {
          id name slug path __typename 
          typeLabel
          openGraph { id image { id url url2x } }
        }
        product {
          id name slug path __typename 
          typeLabel
          openGraph { id image { id url url2x } }
        }
        team {
          id name slug path __typename 
          typeLabel
          openGraph { id image { id url url2x } }
        }
      }
      children {
        __typename id name path slug title subtitle pretitle 
        openGraph { description { text } image { thumbnails { card { url url2x } } } }
        intro { text }
        orderNumber
      }
    
      slices {
        id name slug 
        title
        disabled
        created updated
        outro { text }
        intro { text }
        images {
          id name slug alternateText description { text }
          image { 
            id url url2x type width height thumbnails { mainBanner { url url2x } } 
          }
        }
        pages {
          id name path disabled
        }
        people {
          __typename
          id name slug 
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
          position
          bio { text html(links: { target: "_blank" }) }
          linkedin
          organisation { id name }
        }
        links {
          id name url page { path }
        }
        options { text }
        files {
          id
          name
          slug
          file {
            id
            url
          }
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        }
        videos { 
          url 
          openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        }
        color { value }
        backgroundColor { value }
        backgroundImages {
          image {
            url
          }
        }
        blocks {
          id name slug 
          title 
          subtitle
          link { id url name }
          page { id path name }
          links { id url name }
          pages { id name }
          content { text }
          options { text }
          image { 
            id 
            image {
              url url2x type width height thumbnails { card { url url2x } halfBanner { url url2x } mainBanner { url url2x } } 
            }
          }
        }
        content { text }
        image { id }
        layout { id name slug component }
        organisations {
          id
          name
          url
          logoInverted { type url url2x thumbnails { card { url url2x } } }
          logo { type url url2x thumbnails { card { url url2x } } }
        }
      }
    
  } }
}
`;
